import { useEffect, useState } from "react";

import moment from "moment-timezone";
import { useSelector } from "react-redux";

import { getSelectedClient } from "containers/clients/redux/selectors";

import { clientTimeZoneToMomentTimeZone } from "../containers/calendar/components/Calendar/utils/clientTimeZoneToMomentTimeZone";

interface IUseMomentTimeZone {
  cleanTimeZoneOnUnmount?: boolean;
}
export const useMomentTimeZone = ({
  cleanTimeZoneOnUnmount,
}: IUseMomentTimeZone = {}) => {
  const client = useSelector(getSelectedClient);
  const [timeZone, setTimeZone] = useState(
    clientTimeZoneToMomentTimeZone(client)
  );
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    return () => {
      if (cleanTimeZoneOnUnmount) {
        moment.tz.setDefault();
      }
    };
  }, [cleanTimeZoneOnUnmount]);

  useEffect(() => {
    moment.tz.setDefault(timeZone);
  }, [timeZone]);

  return { moment, setTimeZone, timeZone };
};
