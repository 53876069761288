import { NUMBER_FILTER } from "fieldpro-tools";
import _ from "lodash";

import { FILTER_TAG } from "components/Filter/TypeFilter";
import { TABLE_COLUMN_TYPE } from "components/Table/model";

export const handleColumnsSearch = ({ filterParams, items, columns }: any) => {
  _.forEach(Object.keys(filterParams), (filterKey) => {
    if (
      (_.isArray(filterParams) ||
        _.isObject(filterParams) ||
        _.isString(filterParams)) &&
      _.isEmpty(filterParams[filterKey])
    ) {
      delete filterParams[filterKey];
    }
  });

  let result = items;
  const columnsToFilterOn = Object.keys(filterParams).filter(
    (e) => e !== FILTER_TAG.MORE_FILTER
  );

  const columnTypes = columns.filter((e: any) =>
    columnsToFilterOn.some((f) => f === e.name)
  );

  Object.keys(filterParams).forEach((att) => {
    if (att !== FILTER_TAG.MORE_FILTER) {
      result = result.filter((row: any) => {
        const columnType = columnTypes.filter((e: any) => e.name === att)[0]
          ?.type;
        switch (columnType) {
          case TABLE_COLUMN_TYPE.TEXT:
            return row[att]
              ?.toLowerCase()
              .includes(filterParams[att].toLowerCase());
          case TABLE_COLUMN_TYPE.NUMBER:
            const numberFilterType = filterParams[att]["number_filter_type"];
            let searchValue = filterParams[att]["search_value"];
            searchValue = searchValue === "_" ? "_" : parseInt(searchValue);
            const value = row[att];
            switch (numberFilterType as NUMBER_FILTER) {
              case NUMBER_FILTER.IS:
                return searchValue === "_"
                  ? value === null || value === ""
                  : value === searchValue;
              case NUMBER_FILTER.IS_NOT:
                return value !== searchValue;
              case NUMBER_FILTER.GREATER_THAN:
                return value > searchValue;
              case NUMBER_FILTER.GREATER_THAN_OR_EQUAL:
                return value >= searchValue;
              case NUMBER_FILTER.LESS_THAN:
                return value < searchValue;
              case NUMBER_FILTER.LESS_THAN_OR_EQUAL:
                return value <= searchValue;
              default:
                return;
            }
          case TABLE_COLUMN_TYPE.DATE:
            const startDate = new Date(filterParams[att]["startDate"]);
            const endDate = new Date(filterParams[att]["endDate"]);
            const columnDate = new Date(row[att]);
            return columnDate >= startDate && columnDate <= endDate;
          case TABLE_COLUMN_TYPE.MULTIPLE_CHOICE:
            return _.some(filterParams[att], (e: string) =>
              _.map(row[att], (el: any) => el?.label).includes(e)
            );
          case TABLE_COLUMN_TYPE.SINGLE_CHOICE:
            return _.includes(filterParams[att], row[att]?.label ?? "_");
          case TABLE_COLUMN_TYPE.SWITCH:
            const selectedValue = filterParams[att][0] === true;
            const attValue = Boolean(row[att]);
            return attValue === selectedValue;
        }
        return true; // No filtering if columnType not handled
      });
    }
  });

  return result;
};
