/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import {
  allListsSelector,
  getIsFetchingItemsForList,
} from "containers/lists/redux/selectors";
import { fetchMobileUsersAction } from "containers/users/redux/actions/mobileUserActions";
import { getIsFetchingAllMobileUsers } from "containers/users/redux/selectors/mobileUsersSelector";
import { useActions, useCalendarRange, useTranslations } from "hooks";

import CalendarErrorBoundary from "./CalendarErrorBoundary";
import { fetchEventsActionFunction } from "./redux/actions";
import {
  getIsCreatingEvent,
  getIsDeletingEvent,
  getIsFetchingEvents,
  getIsUpdatingEvent,
} from "./redux/selectors";

/* -------------------------------------------------------------------------- */
/*                          Main Subcategory manager                          */
/* --------------- 1 - Manage the state of the subcategories ---------------- */
/* -------------------------------------------------------------------------- */
interface SubcategoryManagerProps {
  children: React.ReactNode;
}
const SubcategoryManager: React.FC<SubcategoryManagerProps> = ({
  children,
}) => {
  const lang = useTranslations();
  const isFetchingEvents = useSelector(getIsFetchingEvents);
  const isUpdatingEvents = useSelector(getIsUpdatingEvent);
  const isDeletingEvents = useSelector(getIsDeletingEvent);
  const isCreatingEvents = useSelector(getIsCreatingEvent);
  const isFetchingMobileUsers = useSelector(getIsFetchingAllMobileUsers);
  const isFetchingItems = useSelector(getIsFetchingItemsForList);

  const showSnackbar =
    isUpdatingEvents ||
    isFetchingEvents ||
    isFetchingMobileUsers ||
    isDeletingEvents ||
    isCreatingEvents ||
    isFetchingItems;

  return (
    <CalendarErrorBoundary>
      <Box>
        {children}
        <SnackbarMessage
          key={String(showSnackbar)}
          isFetchActionOngoing={showSnackbar}
          lang={lang}
        />
      </Box>
    </CalendarErrorBoundary>
  );
};

/* -------------------------------------------------------------------------- */
/*                     Calendar events subcategory manager                    */
/* ------------ Fetch all events linked to mulitple mobile users ------------ */
/* ---- Fetch all customers linked to the events and store them if needed --- */
/* -------------------------------------------------------------------------- */
interface ICalendarEventsSubManager {
  children: React.ReactNode;
}
const CalendarEventsSubManager: React.FC<ICalendarEventsSubManager> = ({
  children,
}) => {
  const { date } = useCalendarRange();
  const [fetchEventsAction, fetchItemsForList] = useActions([
    fetchEventsActionFunction,
    fetchItemsForListAction,
  ]);
  const allLists = useSelector(allListsSelector);
  const customerList = _.find(allLists, { id: "customer" });

  const fetchInitialEvents = async () => {
    const events = await fetchEventsAction({
      mobile_user_ids: undefined,
      start_time: moment(date).startOf("year").toDate(),
      end_time: moment(date).endOf("year").toDate(),
    });

    if (events) {
      fetchCustomers(events);
    }
  };

  const fetchCustomers = (events: any[]) => {
    const customers = _.map(events, "customer_id");

    const customersToFetch = _.difference(
      customers,
      // ignore customers that are already stored
      _.map(customerList?.items, "_id")
    );

    if (!_.isEmpty(customersToFetch)) {
      fetchItemsForList(
        "customer",
        {
          filters: {
            _id: _.uniq(_.compact(customers)),
          },
        },
        {
          skipStoreUpdate: false,
          storeAsTableItems: true,
        },
        {}
      );
    }
  };

  useEffect(() => {
    fetchInitialEvents();
  }, [moment(date).year()]);

  return <Box>{children}</Box>;
};

/* -------------------------------------------------------------------------- */
/*                  Calendar mobile users subcategory manager                 */
/* ----------------------- Fetch all mobile users --------------------------- */
/* -------------------------------------------------------------------------- */

const CalendarUsersSubManager: React.FC<SubcategoryManagerProps> = ({
  children,
}) => {
  const [fetchMobileUsers] = useActions([fetchMobileUsersAction]);

  useEffect(() => {
    fetchMobileUsers();
  }, []);

  return <Box>{children}</Box>;
};

/* -------------------------------------------------------------------------- */
/*                            DEFINTIONS + EXPORTS                            */
/* -------------------------------------------------------------------------- */

export const CalendarContainerSubcategoryManager: React.FC<
  SubcategoryManagerProps
> = ({ children }) => {
  return (
    <SubcategoryManager>
      <CalendarUsersSubManager>{children}</CalendarUsersSubManager>
    </SubcategoryManager>
  );
};

export const ListCalendarSubcategoryManager: React.FC<
  ICalendarEventsSubManager
> = ({ children }) => {
  return (
    <SubcategoryManager>
      <CalendarUsersSubManager>
        <CalendarEventsSubManager>{children}</CalendarEventsSubManager>
      </CalendarUsersSubManager>
    </SubcategoryManager>
  );
};
