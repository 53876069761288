/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { makeStyles } from "@material-ui/core";
import { Feature, Point, Polygon } from "geojson";
import _ from "lodash";
import {
  GeolocateControl,
  Layer,
  LngLatBoundsLike,
  Map,
  MapboxGeoJSONFeature,
  MapboxMap,
  MapLayerMouseEvent,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import { useSelector } from "react-redux";

import Pin from "assets/images/svg/Pin.svg";
import ScreenErrorBoundary from "components/Errors/ScreenErrorBoundary";
import ClusterLayer from "components/Map/Cluster/ClusterLayer";
import { getMapRegion } from "components/Map/getMapRegion";
import MapLegend from "components/Map/MapLegend";
import SnackbarMessage from "components/Snackbars/SnackbarMessage";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { getAllLegends } from "containers/customers/redux/selectors";
import { getBboxSafe } from "containers/customers/utils/getBboxSafe";
import getMapFeatures, {
  removeShapeId,
} from "containers/customers/utils/getFeatures";
import { getGeoDelimitationCollection } from "containers/customers/utils/getGeoDelimitationCollection";
import { fetchItemsForListAction } from "containers/lists/redux/actions";
import { allListsSelector } from "containers/lists/redux/selectors";
import { getAllowedOwnerOptions } from "containers/lists/utils";
import { teamsSelector } from "containers/teams/redux/selectors";
import { ITerritory } from "containers/territories/model";
import {
  createTerritoriesAction,
  editTerritoriesAction,
} from "containers/territories/redux/actions";
import {
  getTerritories,
  getTerritoriesCount,
} from "containers/territories/redux/selectors";
import { stringifyShape } from "containers/territories/utils/formatTerritory";
import { allMobileUsersSelector } from "containers/users/redux/selectors/mobileUsersSelector";
import { useActions } from "hooks";
import { useMapImage } from "hooks/useMapImage";
import useTranslations from "hooks/useTranslations";
import ICustomer from "model/entities/Customer";
import { IListItem } from "model/entities/ListItem";
import { isPolygon } from "utils/geojson/polygons";

import useGeoDelimitationsCollection from "../../../hooks/useGeoDelimitationsCollection";
import CustomerDialog from "../CustomerDialog";
import { SHAPE_EDIT_MODE } from "../InputShape/InputEditShape";
import CreateTerritoryPanel from "../TerritoryPanel/CreateTerritoryPanel";
import EditTerritoryPanel from "../TerritoryPanel/EditTerritoryPanel";
import { CustomControl, CustomControlWrapper, DrawControl } from "./controls";
import LayerControl from "./controls/LayerControl";
import CustomersMapBanner from "./CustomersMapBanner";
import CustomersMapTerritoriesControlMenu from "./CustomersMapTerritoriesControlMenu";
import { getCustomerFeatures, IColorsByCustomer } from "./features/customers";
import {
  delimitationsFillLayer,
  delimitationsLineLayer,
  forestLayer,
  territoriesFillLayer,
  territoriesLineLayer,
} from "./layers";
import {
  CUSTOMER_PIN_ID,
  CUSTOMER_PINS_LAYER_ID,
  customerGeoDelimFillLayerStyle,
  customerGeoDelimLineLayerStyle,
  customerGeoLelimLinePatternLayerStyle,
  customersCircleLayer,
  customersSymbolLayer,
} from "./layers/customers";
import styles from "./styles";

export const MAP_SETTINGS = {
  CONTAINER_ID: "customers-map-container",
  WIDTH: "calc(100vw - 60px)",
  HEIGHT: "calc(100vh - 65px)",
  MARKER: {
    SIZE: 50,
  },
  SOURCES: {
    TERRITORIES: "territories-source",
    CUSTOMERS: "customers-source",
    CUSTOMERS_PINS: "customers-pins-source",
    CUSTOMERS_GEO_DELIMITATIONS: "customers-geo-delimitations-source",
  },
  STYLE: {
    DEFAULT: "ck3el600w480q1cnsgbgq9i14",
    SATELLITE: "clrrxzqh900gp01o3atrgg8s1",
  },
  CLUSTERS_RADIUS: 40,
  MIN_ZOOM: 1,
  MAX_ZOOM: 22,
  THRESHOLDS: {
    /** Clusters show for zoom < THRESHOLDS.CLUSTERS */
    CLUSTERS: 6,
    /** Pins show for zoom >= THRESHOLDS.PINS */
    PINS: 13,
  },
};

const useStyles = makeStyles(styles);

export interface IMapLegendLabels {
  [key: string]: {
    name: string;
    options: string[];
  };
}

export interface ICustomerLocation {
  _id: string;
  _lng: number;
  _lat: number;
  [key: string]: any;
}

export interface IClusterProperties {
  cluster: boolean;
  cluster_id: number;
  point_count: number;
}

export interface ICustomersMapProps {
  isLoadingCustomers: boolean;
  customerLocations: ICustomerLocation[];
  /** Can differ from customerLocations.length when there are filters applied */
  customersTotal: number;
  legendSelected?: string;
  colorsByCustomer: IColorsByCustomer;
  legendColors: string[];
  openCreateTerritoryId?: string;
  changeLegendSelected: (legend: string | undefined) => void;
  onConfirmEditItem: (listId: string, item: IListItem) => void;
  onCloseCreatePanel: () => void;
  FilterComponent?: React.ReactNode;
  customersCount?: number;
  onClickAddTerritory: () => void;
  onClickDownloadTerritories: () => void;
  onClickSeeList: () => void;
  onClickTerritoriesSettings: () => void;
  onClickImportTerritories: () => void;
}

export const CustomersMap: React.FunctionComponent<ICustomersMapProps> = ({
  isLoadingCustomers,
  customerLocations,
  customersTotal,
  onConfirmEditItem,
  onCloseCreatePanel,
  changeLegendSelected,
  colorsByCustomer,
  openCreateTerritoryId,
  FilterComponent,
  onClickAddTerritory,
  onClickDownloadTerritories,
  onClickSeeList,
  onClickTerritoriesSettings,
  onClickImportTerritories,
  legendSelected,
}) => {
  const allLegends = useSelector(getAllLegends);
  const currentLegend = allLegends?.find((l) => l.tag === legendSelected);
  const mapRef = useRef() as RefObject<any>;
  const classes = useStyles();
  const lang = useTranslations();

  const client = useSelector(getSelectedClient);
  const lists = useSelector(allListsSelector);
  const fetchItemsForList = useActions(fetchItemsForListAction);

  const customerList = _.find(lists, { id: "customer" });
  const _customersCount = customerLocations?.length ?? 0;
  const territoryScope = client.scope_of_territory;
  const map: MapboxMap | undefined = mapRef?.current?.getMap
    ? mapRef?.current?.getMap()
    : undefined;

  /**
   * Redux state and actions
   */
  const territories = useSelector(getTerritories);
  const _territoriesCount = useSelector(getTerritoriesCount);

  const createTerritories = useActions(createTerritoriesAction);
  const editTerritories = useActions(editTerritoriesAction);

  /**
   * Owners
   */
  const mobileUsers = useSelector(allMobileUsersSelector);
  const teams = useSelector(teamsSelector);
  const territoryOwners = getAllowedOwnerOptions(
    territoryScope,
    mobileUsers,
    teams
  );

  /**
   * Toggles
   */
  // #region
  /** Toggle states  */
  // TODO: remove condition on mex__volcafe
  const [mapStyleId, setMapStyleId] = useState(
    client.dbname === "mex__volcafe"
      ? MAP_SETTINGS.STYLE.SATELLITE
      : MAP_SETTINGS.STYLE.DEFAULT
  );

  const [displayCustomers, setDisplayCustomers] = useState(true);
  const [displayClusters, setDisplayClusters] = useState(true);
  const [displayTerritories, setDisplayTerritories] = useState(
    client.activate_territory === true
  );
  const [displayDeforestation, setDisplayDeforestation] = useState(false);

  const [customerGeoAttributesToDisplay, setDisplayedGeoDelimAttributes] =
    useState<Record<string, boolean>>({});

  /** Toggle helpers  */

  const toggleGeoDelimAttribute = (attr: string) => {
    setDisplayedGeoDelimAttributes((prev) => ({
      ...prev,
      [attr]: !prev[attr],
    }));
  };

  const toggleDisplayCustomers = () => setDisplayCustomers((value) => !value);

  const toggleDisplayClusters = () => {
    setDisplayClusters((value) => {
      const newValue = !value;
      if (newValue && approxZoom > MAP_SETTINGS.THRESHOLDS.CLUSTERS) {
        map?.zoomTo(MAP_SETTINGS.THRESHOLDS.CLUSTERS - 0.1, { duration: 2000 });
      }
      return newValue;
    });
  };
  const toggleDisplayDeforestation = () =>
    setDisplayDeforestation(!displayDeforestation);

  const toggleDisplayTerritories = () =>
    setDisplayTerritories((value) => !value);

  // #endregion

  /**
   * Customer Dialog
   */
  const [selectedCustomer, setSelectedCustomer] = useState<
    ICustomer | undefined
  >();

  const handleOpenCustomerDialog = (newCustomer: ICustomer) => {
    setSelectedCustomer(newCustomer);
  };

  const handleCloseCustomerDialog = () => {
    setSelectedCustomer(undefined);
  };

  /**
   * View State
   */

  const initialViewState = {
    latitude: 12,
    longitude: 12,
    zoom: 2,
    width: MAP_SETTINGS.WIDTH,
    height: MAP_SETTINGS.HEIGHT,
  };

  // Sync Map's viewState (with slight delay due to debounce)
  // const [viewState, setViewState] = useState<ViewState>({
  //   latitude: initialViewState.latitude,
  //   longitude: initialViewState.longitude,
  //   zoom: initialViewState.zoom,
  //   pitch: 0,
  //   bearing: 0,
  //   padding: { top: 0, bottom: 0, left: 0, right: 0 },
  // });

  /**
   * Territory features mode
   */
  const [mode, setMode] = useState<"VIEW" | "CREATE" | "EDIT" | "RESET">(
    "VIEW"
  );

  // TODO: this is not good
  const shapeEditMode =
    mode === "EDIT" ? SHAPE_EDIT_MODE.DRAW : SHAPE_EDIT_MODE.EDIT;

  /**
   * Draw control
   */
  // #region

  const [drawFeatures, setDrawFeatures] = useState<Feature[]>([]);

  const onDrawFeatures = useCallback(
    (e: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent) => {
      setDrawFeatures(e.features);
    },
    []
  );

  const onDeleteFeatures = useCallback((e: MapboxDraw.DrawDeleteEvent) => {
    setDrawFeatures((currFeatures) => {
      return _.differenceBy(currFeatures, e.features, "id");
    });
  }, []);

  // Goal is to only draw 1 polygon at a time (for now)
  // See https://stackoverflow.com/a/51075725/6627882
  const onDrawModeChange = useCallback((control: MapboxDraw) => {
    if (!control) {
      console.error("Missing MapboxDraw control");
      return;
    }

    const data = control.getAll();

    if (control.getMode() == "draw_polygon") {
      // ID of the added template empty feature
      const newBlankFeatureId = _.last(data.features)?.id;
      const polygons = _.filter(data.features, isPolygon);
      const polygonIdsToDelete: string[] = [];
      _.forEach(polygons, (feature) => {
        if (!feature.id || feature.id === newBlankFeatureId) {
          return;
        }
        polygonIdsToDelete.push(String(feature.id));
      });

      control.delete(polygonIdsToDelete);
    }
  }, []);
  // #endregion

  /**
   * GeoJSON Features representing the territories
   */
  // #region
  const [territoryFeatures, setTerritoryFeatures] = useState(() => {
    const initFeatures = getMapFeatures({ territories });
    return initFeatures;
  });

  // 'MEX - Volcafe' quick and dirty code for demo
  const [delimitationCollection, setDelimitationCollection] = useState(() =>
    getGeoDelimitationCollection(customerList)
  );

  useEffect(() => {
    setDelimitationCollection(getGeoDelimitationCollection(customerList));
  }, [customerList]);

  // Update features after creation / edition (store update)
  useEffect(() => {
    const updateFeatures = getMapFeatures({ territories });
    setTerritoryFeatures(updateFeatures);
  }, [territories]);

  const territoriesCollection = {
    type: "FeatureCollection" as const,
    features: territoryFeatures,
  };

  // #endregion

  /**
   * GeoJSON Features representing the customers
   */
  // #region
  const [initFitBoundsDone, setInitFitBoundsDone] = useState(false);
  const [customerFeatures, setCustomersFeatures] = useState(() => {
    const initFeatures = getCustomerFeatures(
      customerLocations,
      colorsByCustomer,
      currentLegend
    );
    return initFeatures;
  });

  useMapImage({
    map,
    path: Pin,
    name: CUSTOMER_PIN_ID,
    sdf: true,
  });

  // TODO: FP-6802 - Instead of recomputing color property, we could add the property
  // in getCustomerFeature and use a style-function in customersCircleLayer ?
  useEffect(() => {
    const newFeatures = getCustomerFeatures(
      customerLocations,
      colorsByCustomer,
      currentLegend
    );
    setCustomersFeatures(newFeatures);
  }, [
    map,
    customerLocations,
    colorsByCustomer,
    allLegends,
    legendSelected,
    currentLegend,
  ]);

  useEffect(() => {
    if (_.isEmpty(customerFeatures) || initFitBoundsDone) {
      return;
    }

    // TODO: should be improved to remove all outliers, and keep say [10 - 90] percentiles
    // Currently removes "test" points like [0, 0] (and [0, N], [N, 0])
    const removeOutliers = (features: Feature<Point>[]) =>
      _.reject(features, (feat) => {
        return _.includes(feat.geometry.coordinates, 0);
      });

    const bbox = getBboxSafe({
      type: "FeatureCollection" as const,
      features: removeOutliers(customerFeatures),
    });

    if (map && bbox) {
      try {
        map.fitBounds(bbox as LngLatBoundsLike, { padding: 30 });
      } catch (e) {
        console.error(e);
      }
      setInitFitBoundsDone(true);
    }
  }, [map, initFitBoundsDone, customerFeatures]);

  const customersCollection = {
    type: "FeatureCollection" as const,
    features: customerFeatures,
  };

  const onClickCustomer = async (customerId: string) => {
    if (!customerList) {
      return;
    }

    const data = await fetchItemsForList(
      customerList.id,
      {
        filters: { _id: customerId },
        limit: 1,
        detail: true,
      },
      undefined,
      {}
    );

    await handleOpenCustomerDialog(data?.items?.[0] as ICustomer);
  };

  // #endregion

  /**
   * Create Panel handlers
   */
  // #region
  const onDrawCreatePanel = () => {
    setMode("CREATE");
  };

  const drawnPolygon = drawFeatures?.[0] as Feature<Polygon>;

  const disableSaveCreate =
    !drawnPolygon || _.isEmpty(drawnPolygon.geometry?.coordinates);

  const onSaveCreatePanel = async (territory: Omit<ITerritory, "shape">) => {
    onCloseCreatePanel();

    const newTerritory: ITerritory = {
      ...territory,
      shape: {
        ...drawnPolygon,
        properties: {
          territoryId: territory.id,
          territoryName: territory.name,
        },
      },
    };

    await createTerritories([newTerritory]);

    setMode("VIEW");
  };

  const onCloseAndCancelCreatePanel = () => {
    onCloseCreatePanel();
    setMode("VIEW");
  };
  // #endregion

  /**
   * Clusters
   */
  // #region

  /**
   * WARNING: this is not always accurate, because it is not a true react state.
   * It is only updated on re-render (caused by other updates, not necessarily on every user pan/zoom).
   * To be more accurate, move to a controlled viewState (but also less performant ?)
   */
  const approxZoom = map?.getZoom() ?? 0; // viewState.zoom

  // #endregion

  /**
   * Click feature
   */
  // #region

  /**
   * NOTE: MapboxGeoJSONFeature has layer, source, sourceLayer and state keys on top of default GeoJSON Feature
   */
  const [selectedFeature, setSelectedFeature] = useState<
    MapboxGeoJSONFeature | undefined
  >();

  const [selectedTerritory, setSelectedTerritory] = useState<
    ITerritory | undefined
  >();

  useEffect(() => {
    setSelectedTerritory(
      _.find(territories, {
        id: selectedFeature?.properties?.territoryId,
      }) as ITerritory | undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  const onOpenEditPanel = (selectedFeature: MapboxGeoJSONFeature) => {
    mode === "VIEW" && setSelectedFeature(selectedFeature);
  };

  // Layers that can be clicked / hovered
  const interactiveLayerIds = _.compact(
    approxZoom >= MAP_SETTINGS.THRESHOLDS.PINS
      ? [
          territoriesFillLayer.id,
          CUSTOMER_PINS_LAYER_ID,
          customerGeoDelimFillLayerStyle.id,
        ]
      : approxZoom >= MAP_SETTINGS.THRESHOLDS.CLUSTERS
      ? [territoriesFillLayer.id, customerGeoDelimFillLayerStyle.id]
      : []
  );

  const onClickMap = async (event: MapLayerMouseEvent) => {
    // Customer
    const clickedCustomer = map?.queryRenderedFeatures(event.point, {
      layers: _.intersection([CUSTOMER_PINS_LAYER_ID], interactiveLayerIds),
    })?.[0];

    if (clickedCustomer) {
      await onClickCustomer(clickedCustomer?.properties?.customerId);
      return; // Only one action at a time; Customer has priority over Territory
    }

    // Territory
    const clickedTerritory = map?.queryRenderedFeatures(event.point, {
      layers: _.intersection([territoriesFillLayer.id], interactiveLayerIds),
    })?.[0];

    if (clickedTerritory) {
      onOpenEditPanel(clickedTerritory);
    }
  };
  // #endregion

  /**
   * Hover states
   */
  // #region
  const [hoveredFeature, setHoveredFeature] = useState<MapboxGeoJSONFeature>();

  // Hover tooltip
  const [tooltipCoords, setTooltipCoords] = useState<
    { lng: number; lat: number } | null | undefined
  >();

  // const onMoveCallback = ({ viewState }: ViewStateChangeEvent) => {
  //   setViewState(viewState);
  // };
  // const onMove = useDebouncedCallback(onMoveCallback, 300, []);

  // debounced version seems buggy (event not persisted ?)
  const onMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      const { features, lngLat, originalEvent } = event;

      originalEvent.preventDefault();
      originalEvent.stopPropagation();

      setTooltipCoords(lngLat);

      const newHoveredGeoDelimFeature = _.find(features, {
        source: MAP_SETTINGS.SOURCES.CUSTOMERS_GEO_DELIMITATIONS,
      });

      const newHoveredTerritoryFeature = !newHoveredGeoDelimFeature
        ? _.find(features, { source: MAP_SETTINGS.SOURCES.TERRITORIES })
        : null;

      // Clear previous hover
      if (
        hoveredFeature &&
        (newHoveredGeoDelimFeature?.id !== hoveredFeature?.id ||
          newHoveredGeoDelimFeature?.source !== hoveredFeature?.source) &&
        (newHoveredTerritoryFeature?.id !== hoveredFeature?.id ||
          newHoveredTerritoryFeature?.source !== hoveredFeature?.source)
      ) {
        map?.setFeatureState(hoveredFeature, { hovered: false });
        setHoveredFeature(undefined);
      }

      // Set current hover
      if (newHoveredGeoDelimFeature) {
        map?.setFeatureState(newHoveredGeoDelimFeature, { hovered: true });
        setHoveredFeature(newHoveredGeoDelimFeature);
      } else if (newHoveredTerritoryFeature) {
        map?.setFeatureState(newHoveredTerritoryFeature, { hovered: true });
        setHoveredFeature(newHoveredTerritoryFeature);
      }
    },
    [map, hoveredFeature]
  );

  /**
   * TODO: alternate method with onMouseEnter/onMouseLeave (does not work properly ?)
   *
   * const onMouseEnter = useCallback(
   *   (event: MapLayerMouseEvent) => {
   *     const features = map.queryRenderedFeatures(event.point, {
   *       layers: ["territories-fill"],
   *     });
   *     const newHoveredFeature = features?.[0];
   *     map.setFeatureState(newHoveredFeature, { hovered: true });
   *     setHoveredFeature(newHoveredFeature);
   *   },
   *   [map]
   * );
   *
   * const onMouseLeave = useCallback(() => {
   *   map.setFeatureState(hoveredFeature, { hovered: false });
   *   setHoveredFeature(undefined);
   * }, [map, hoveredFeature]);
   */

  // #endregion

  /**
   * Edit Panel handlers
   */
  // #region

  const disableSaveEdit = !(
    selectedTerritory ||
    (drawnPolygon && _.isEmpty(drawnPolygon?.geometry?.coordinates))
  );

  const onSaveEditPanel = async (territory: ITerritory) => {
    if (disableSaveEdit) {
      return;
    }

    const cleanDrawnPolygon = {
      ...removeShapeId(drawnPolygon),
      properties: {
        ...territory.shape.properties,
        territoryName: territory.name,
      },
    };
    const shape =
      selectedTerritory && drawnPolygon ? cleanDrawnPolygon : territory.shape;

    if (_.isEmpty(shape)) {
      window.alert("Cannot save, because territory boundaries are not defined");
      return;
    }

    const editedTerritory = stringifyShape({
      ...territory,
      owners: _.compact(territory.owners), // Fix bug in BE, sending [null] to FE
      shape,
    });

    await editTerritories([editedTerritory]);

    onCloseEditPanel();
  };

  const onEditShapeEditPanel = () => {
    if (!selectedFeature) {
      console.error("Missing selectedFeature");
      return;
    }

    // Instead, we toggle visibilty of feature (see below)
    // -> to avoid manipulating the array of features
    // (will be updated if the territories are updated in the store though)
    // setFeatures((features) => {
    //   const newFeatures = _.reject(
    //     features,
    //     // Note: feature.id is a bit unstable between features state and MapGL uncontrolled features
    //     (feature) =>
    //       getProperty(feature, "territoryId") ===
    //       getProperty(selectedFeature, "territoryId")
    //   );
    //   return newFeatures;
    // });

    map?.setFeatureState(selectedFeature, { hidden: true });

    const initDrawFeature =
      convertMapboxFeatureToGeoJsonFeature(selectedFeature);

    // ORDER MATTERS
    setDrawFeatures(_.compact([initDrawFeature]));
    setMode("EDIT");
  };

  const onResetShapeEditPanel = () => {
    if (!selectedFeature) {
      console.error("Missing selectedFeature");
      return;
    }

    map?.setFeatureState(selectedFeature, { hidden: true });

    // Order matters here
    setDrawFeatures([]);
    setMode("RESET");
  };

  const onCloseEditPanel = useCallback(() => {
    // TODO: Attempted to avoid glitch of old shape re-appearing before new shape is rendered,
    // by placing making it visible only after the edit goes through. But it's still the same...
    if (selectedFeature) {
      map?.setFeatureState(selectedFeature, { hidden: false });
    }
    setSelectedTerritory(undefined);
    setSelectedFeature(undefined);
    setMode("VIEW");
  }, [map, selectedFeature]);

  // Close the EditPanel when we open the CreatePanel
  useEffect(() => {
    if (openCreateTerritoryId) {
      onCloseEditPanel();
    }
  }, [openCreateTerritoryId, onCloseEditPanel]);

  // #endregion

  const circleSize = Math.max(1, Math.min(4.5, approxZoom / 2));
  const pinSize = getPinSize(approxZoom);

  const { customersGeoLayerFeatureCollection } = useGeoDelimitationsCollection({
    customerGeoAttributesToDisplay,
    customerLocations,
    map,
  });

  return (
    <ScreenErrorBoundary>
      <div
        id={MAP_SETTINGS.CONTAINER_ID}
        data-testid="customers-map-container"
        style={{
          position: "relative",
          width: MAP_SETTINGS.WIDTH,
          height: MAP_SETTINGS.HEIGHT,
        }}
      >
        {isLoadingCustomers && (
          <SnackbarMessage isFetchActionOngoing lang={lang} />
        )}

        {/* Without the check on isLoadingCustomers, the banner appears for a fraction of sec + the map height is not well calculated on mount */}
        {!isLoadingCustomers && customersTotal === 0 && <CustomersMapBanner />}
        {/* {mode?.constructor?.name} */}

        {/* Debug zoom */}
        {/* <Box
          color="rgba(255,255,255,0.4)"
          style={{
            position: "absolute",
            top: 14,
            left: 54,
            zIndex: 10,
            pointerEvents: "none",
          }}
        >{`Zoom: ${approxZoom.toFixed(2)}`}</Box> */}
        <Map
          initialViewState={initialViewState}
          // viewState={viewState} // WARNING: do not pass initialViewState AND viewState. It is better for performance to leave component uncontrolled ?
          // onMove={onMove} // needed to "control" the viewState, but better if we can leave the map uncontrolled ? (For perf)
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_API}
          mapStyle={`mapbox://styles/smala/${mapStyleId}`}
          style={{
            width: MAP_SETTINGS.WIDTH,
            height: MAP_SETTINGS.HEIGHT,
            marginLeft: "-20px",
            marginTop: "-20px",
          }}
          ref={mapRef}
          // interactions don't make sense when you can't see the features in full (points, territories)
          interactiveLayerIds={interactiveLayerIds}
          onClick={onClickMap}
          // If onMouseMove is defined, mapbox-gl-draw does not work properly... (drag, adjust polygon break)
          onMouseMove={mode === "VIEW" ? onMouseMove : undefined}
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          // Min & Max zoom considerations: https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/#zoom-levels
          minZoom={MAP_SETTINGS.MIN_ZOOM}
          maxZoom={MAP_SETTINGS.MAX_ZOOM}
          worldview={getMapRegion(client)}
        >
          {mode === "VIEW" ? (
            <>
              <CustomControlWrapper position="top-left">
                <>
                  {FilterComponent}
                  <LayerControl
                    displayGeoDelimAttributes={customerGeoAttributesToDisplay}
                    displayClusters={displayClusters}
                    displayCustomers={displayCustomers}
                    displayTerritories={displayTerritories}
                    displayDeforestation={displayDeforestation}
                    satelliteView={mapStyleId === MAP_SETTINGS.STYLE.SATELLITE}
                    toggleSatelliteView={() =>
                      setMapStyleId((styleId) =>
                        styleId === MAP_SETTINGS.STYLE.SATELLITE
                          ? MAP_SETTINGS.STYLE.DEFAULT
                          : MAP_SETTINGS.STYLE.SATELLITE
                      )
                    }
                    toggleDisplayCustomers={toggleDisplayCustomers}
                    toggleDisplayTerritories={toggleDisplayTerritories}
                    toggleDisplayClusters={toggleDisplayClusters}
                    toggleDisplayDeforestation={toggleDisplayDeforestation}
                    toggleCustomerGeoAttribute={toggleGeoDelimAttribute}
                  />
                </>
              </CustomControlWrapper>

              <GeolocateControl position="top-left" />

              <NavigationControl position="top-left" />

              <CustomControlWrapper position="top-right">
                <CustomControl
                  TerritoriesMenu={
                    <CustomersMapTerritoriesControlMenu
                      onClickAddTerritory={onClickAddTerritory}
                      onClickDownloadTerritories={onClickDownloadTerritories}
                      onClickImportTerritories={onClickImportTerritories}
                      onClickSeeList={onClickSeeList}
                      onClickTerritoriesSettings={onClickTerritoriesSettings}
                      onClickEditTerritory={(t) => {
                        setSelectedTerritory(t);
                      }}
                    />
                  }
                  LegendMenu={
                    <MapLegend
                      defaultValue={currentLegend?.tag}
                      changeLegendSelected={(legend) => {
                        changeLegendSelected(legend);
                      }}
                      onClickCustomer={onClickCustomer}
                    />
                  }
                />
              </CustomControlWrapper>
            </>
          ) : (
            <DrawControl
              initialFeatures={mode === "EDIT" ? drawFeatures : undefined}
              position="top-left"
              onCreate={onDrawFeatures}
              onUpdate={onDrawFeatures}
              onDelete={onDeleteFeatures}
              onModeChange={onDrawModeChange}
              defaultMode={mode === "EDIT" ? "direct_select" : "draw_polygon"}
              defaultModeOptions={
                mode === "EDIT"
                  ? { featureId: selectedFeature?.id ?? "" }
                  : undefined
              }
              displayControlsDefault={false}
              controls={{
                polygon: true,
                trash: true,
              }}
            />
          )}

          <Source
            id={MAP_SETTINGS.SOURCES.CUSTOMERS_GEO_DELIMITATIONS}
            type="geojson"
            data={customersGeoLayerFeatureCollection}
            generateId
          >
            <Layer {...customerGeoDelimLineLayerStyle} />
            <Layer {...customerGeoDelimFillLayerStyle} />
            <Layer {...customerGeoLelimLinePatternLayerStyle} />
          </Source>

          {displayTerritories && (
            <Source
              id={MAP_SETTINGS.SOURCES.TERRITORIES}
              type="geojson"
              data={territoriesCollection}
              // promoteId="territoryId" // This needs to be an integer or a castable string... (see https://stackoverflow.com/a/74875834/6627882)
              generateId // ... so instead we generate random IDs
            >
              <Layer {...territoriesFillLayer} />
              <Layer {...territoriesLineLayer} />
            </Source>
          )}

          {displayCustomers && displayClusters && (
            <ClusterLayer
              zoom={approxZoom}
              maxZoom={MAP_SETTINGS.THRESHOLDS.CLUSTERS}
              clustersRadius={MAP_SETTINGS.CLUSTERS_RADIUS}
              points={customerFeatures}
              map={map}
            />
          )}

          {displayCustomers && (
            <Source
              id={MAP_SETTINGS.SOURCES.CUSTOMERS}
              type="geojson"
              generateId // For hovers
              buffer={0} // https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/#adjusting-the-buffer
              data={customersCollection}
            >
              <Layer
                {...customersCircleLayer(circleSize)}
                minzoom={displayClusters ? MAP_SETTINGS.THRESHOLDS.CLUSTERS : 0}
                maxzoom={MAP_SETTINGS.THRESHOLDS.PINS}
              />
            </Source>
          )}

          {displayCustomers && (
            <Source
              id={MAP_SETTINGS.SOURCES.CUSTOMERS_PINS}
              type="geojson"
              generateId
              buffer={0}
              data={customersCollection}
            >
              <Layer
                {...customersSymbolLayer(pinSize)}
                minzoom={MAP_SETTINGS.THRESHOLDS.PINS}
              />
            </Source>
          )}

          <Source
            id={"source-geo-delimitations"}
            type="geojson"
            data={delimitationCollection}
            generateId // ... so instead we generate random IDs
          >
            <Layer {...delimitationsFillLayer} />
            <Layer {...delimitationsLineLayer} />
          </Source>

          {/* TODO: add a proper toggle, this is for a demo */}
          {displayDeforestation && (
            <Source
              id="source-raster-tiles"
              type="raster"
              tiles={[
                "https://tiles.globalforestwatch.org/umd_tree_cover_loss/v1.11/tcd_30/{z}/{x}/{y}.png?start_year=2021", // black background, but fast
                // "https://tiles.globalforestwatch.org/umd_tree_cover_loss/v1.10/dynamic/{z}/{x}/{y}.png?start_year=2021", // transparent, but slow
              ]}
            >
              <Layer {...forestLayer} />
            </Source>
          )}

          {hoveredFeature && tooltipCoords && (
            <Popup
              className={classes.MapTooltip}
              longitude={tooltipCoords.lng}
              latitude={tooltipCoords.lat}
              closeButton={false}
              offset={[0, -16] as [number, number]}
              anchor="bottom"
            >
              {hoveredFeature.properties?.territoryName ??
                hoveredFeature.properties?.name}
            </Popup>
          )}
        </Map>

        {/**
         * Modals, Panels and everything that does not require to be rendered inside a MapGL component
         * are rendered outside of it, and relatively to the same customers-map-container
         * - it is easier to define their position,
         * - we avoid unwanted mouse interactions (e.g no pan and zoom while mouse over legend),
         * - it is easier for us to check these components are rendered in UTs
         *   (indeed, everything that is rendered inside <MapGL/> will not appear in react-testing-library)
         */}
        {selectedCustomer ? (
          <CustomerDialog
            customer={selectedCustomer}
            onConfirm={(item: IListItem) => onConfirmEditItem("customer", item)}
            onClose={handleCloseCustomerDialog}
          />
        ) : null}

        {openCreateTerritoryId && (
          <div
            data-testid="create-panel-container"
            className={classes.PanelContainer}
            style={{ height: MAP_SETTINGS.HEIGHT }}
          >
            <CreateTerritoryPanel
              key={openCreateTerritoryId}
              territoryId={openCreateTerritoryId}
              ownerOptions={territoryOwners}
              onCancel={onCloseAndCancelCreatePanel}
              onClose={onCloseAndCancelCreatePanel}
              onDraw={onDrawCreatePanel}
              onSave={!disableSaveCreate ? onSaveCreatePanel : undefined}
            />
          </div>
        )}
        {selectedTerritory && (
          <div
            data-testid="edit-panel-container"
            className={classes.PanelContainer}
            style={{ height: MAP_SETTINGS.HEIGHT }}
          >
            <EditTerritoryPanel
              key={selectedTerritory.id}
              editMode={shapeEditMode}
              territory={selectedTerritory}
              ownerOptions={territoryOwners}
              onResetShape={onResetShapeEditPanel}
              onEditShape={onEditShapeEditPanel}
              onClose={onCloseEditPanel}
              onCancel={onCloseEditPanel}
              onSave={!disableSaveEdit ? onSaveEditPanel : undefined}
            />
          </div>
        )}
      </div>
    </ScreenErrorBoundary>
  );
};

// const getMapHeight = () => {
//   return document.getElementById(MAP.CONTAINER_ID)?.offsetHeight;
// };

const convertMapboxFeatureToGeoJsonFeature = (
  feature: MapboxGeoJSONFeature
) => {
  return {
    type: feature.type,
    geometry: feature.geometry, // ?? feature._geometry,
    properties: feature.properties,
  };
};

export const getPinSize = (zoom: number): number => {
  if (zoom <= 13) {
    return 0.9;
  }
  if (zoom >= 18) {
    return 3;
  }

  const PIN_SIZE_BY_ZOOM: Record<number, number> = {
    13: 0.9,
    14: 1,
    15: 1.25,
    16: 1.5,
    17: 2.25,
  };
  return PIN_SIZE_BY_ZOOM[Math.floor(zoom)];
};

export default CustomersMap;
