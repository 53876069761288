import React from "react";

import { Box, Fade, makeStyles } from "@material-ui/core";
import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { Moment } from "moment";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { StrokeLowEnphasis } from "assets/colors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import BoardItem, { IBoardItemProps } from "./BoardItem";
import { BOX_HEIGHT } from "./Calendar";
import { clientTimeZoneToMomentTimeZone } from "./utils/clientTimeZoneToMomentTimeZone";
import { getBoardSlotDateTimeString } from "./utils/getBoardSlotDateTimeString";
import {
  calculateMarginTop,
  getEventRowPosition,
} from "./utils/getEventRowPosition";
import { getCalendarWorkingHours, getTimesOfDay } from "./utils/getTimesOfDay";

interface IBoardStylesProps {
  TIMES_OF_DAY: string[];
  BOX_HEIGHT: number;
}
const useStyles = makeStyles({
  boardContainer: ({ TIMES_OF_DAY, BOX_HEIGHT }: IBoardStylesProps) => {
    return {
      display: "grid",
      gridTemplateRows: `repeat(${_.size(TIMES_OF_DAY) * 2}, ${
        BOX_HEIGHT / 2
      }px)`,
      borderRight: `solid 2px ${StrokeLowEnphasis}`,
      width: "100%",
    };
  },
  box: ({ BOX_HEIGHT }: IBoardStylesProps) => ({
    borderBottom: `2px solid ${StrokeLowEnphasis}`,
    height: `${BOX_HEIGHT}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // Center vertically
    background: "none",
  }),
});

export type TEventPlaceHolder = Pick<
  TCalendarEvent,
  "start_time" | "end_time" | "repetition"
> & {
  component: React.ReactNode;
};

export interface IBoardProps
  extends Pick<IBoardItemProps, "onClickApproveEvent" | "onClickDeclineEvent"> {
  events: TCalendarEvent[];
  eventPlaceHolders?: TEventPlaceHolder[];
  activeBoardItems?: string[];
  boardId: string;
  boardContainerProps?: React.ComponentProps<"div">;
  draggable?: boolean;
  onClickTimeSlot?: (date: Moment) => void;
  onEditEvent: (event: TCalendarEvent) => void;
  onClickDeleteEvent: (event: TCalendarEvent) => void;
}
function Board({
  onEditEvent,
  onClickDeleteEvent,
  events,
  boardId,
  draggable = true,
  onClickTimeSlot,
  eventPlaceHolders = [],
  activeBoardItems = [],
  onClickApproveEvent,
  onClickDeclineEvent,
}: Readonly<IBoardProps>) {
  const client = useSelector(getSelectedClient);
  const { moment } = useMomentTimeZone();
  const TIMES_OF_DAY = getTimesOfDay(client, moment);
  const classes = useStyles({ TIMES_OF_DAY, BOX_HEIGHT });

  const { minEventStartTime } = getCalendarWorkingHours(client, moment);
  return (
    <Droppable droppableId={boardId} key={boardId} isDropDisabled={true}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            data-testid={`board-${boardId}`}
          >
            <Box className={classes.boardContainer} position={"relative"}>
              {/* DRAW TIME SLOTS CONTAINERS */}
              <Box
                height={`calc(${TIMES_OF_DAY.length} * ${BOX_HEIGHT}px)`}
                position={"absolute"}
                top={0}
                width={"100%"}
              >
                {_.times(TIMES_OF_DAY.length, (index) => {
                  const { dateFormatted, momentDate } =
                    getBoardSlotDateTimeString({
                      day: boardId,
                      time: TIMES_OF_DAY[index],
                      moment,
                      clientTimezone: clientTimeZoneToMomentTimeZone(client),
                    });
                  return (
                    <Droppable droppableId={dateFormatted} key={dateFormatted}>
                      {(provided2) => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.droppableProps}
                        >
                          <Box
                            className={classes.box}
                            key={index}
                            width={"100%"}
                            onClick={() =>
                              onClickTimeSlot && onClickTimeSlot(momentDate)
                            }
                            data-testid={`timeslot-${dateFormatted}`}
                          >
                            {provided2.placeholder}
                          </Box>
                        </div>
                      )}
                    </Droppable>
                  );
                })}
              </Box>

              {/* DRAW EVENTS */}
              {_.map(_.reverse(events), (event, index) => {
                const active = activeBoardItems.includes(event?.id || "");
                return (
                  <Draggable
                    key={event.id}
                    draggableId={event.id as string}
                    index={index}
                    isDragDisabled={
                      !draggable ||
                      event.status === CalendarEventStatus.COMPLETED
                    }
                  >
                    {(provided1) => {
                      const smallBoardItem =
                        moment(event.end_time).diff(
                          event.start_time,
                          "minutes"
                        ) <= 30;
                      return (
                        <div
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {..._.omit(provided1.dragHandleProps, ["style"])}
                          className={classes.box}
                          style={{
                            width: "100%",
                            height: "97%",
                            background: "white",
                            borderBottom: "none",
                            zIndex: 5,
                            gridRow: getEventRowPosition(
                              event,
                              moment
                                .utc(event.start_time)
                                .hour(minEventStartTime.utc().hour()),
                              moment
                            ),
                            paddingTop: !smallBoardItem ? "5px" : "3px",
                            paddingBottom: !smallBoardItem ? "5px" : "0px",
                            position: "absolute",
                            marginTop: calculateMarginTop(event, moment),
                            ...provided1.draggableProps.style,
                          }}
                          data-testid={`event-${event.id}`}
                        >
                          <BoardItem
                            event={event}
                            boxProps={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              textAlign: "center",
                            }}
                            onEditEvent={onEditEvent}
                            onClickDelete={onClickDeleteEvent}
                            onClickApproveEvent={onClickApproveEvent}
                            onClickDeclineEvent={onClickDeclineEvent}
                            active={active}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}

              {/* DRAW PLACEHOLDERS */}

              {_.map(eventPlaceHolders, (placeholder, index) => {
                const smallBoardItem =
                  moment(placeholder.end_time).diff(
                    placeholder.start_time,
                    "minutes"
                  ) <= 30;
                return (
                  <Fade
                    in
                    key={
                      //only re-animate if start_time/end_time change
                      placeholder.start_time?.toString() +
                      placeholder.end_time?.toString()
                    }
                  >
                    <Box
                      key={index}
                      className={classes.box}
                      style={{
                        width: "100%",
                        height: "97%",
                        maxHeight: "150px",
                        background: "white",
                        borderBottom: "none",
                        zIndex: 5,
                        gridRow: getEventRowPosition(
                          _.pick(placeholder, [
                            "start_time",
                            "end_time",
                          ]) as TCalendarEvent,
                          moment
                            .utc(placeholder.start_time)
                            .hour(minEventStartTime.utc().hour()),
                          moment
                        ),
                        paddingTop: !smallBoardItem ? "5px" : "3px",
                        paddingBottom: !smallBoardItem ? "5px" : "0px",
                        position: "absolute",
                        marginTop: calculateMarginTop(placeholder, moment),
                      }}
                    >
                      {placeholder.component}
                    </Box>
                  </Fade>
                );
              })}
            </Box>
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}

export default Board;
